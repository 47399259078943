import { useEffect,useRef,useState  } from 'react'
import * as React from "react";
import { motion, AnimatePresence } from 'framer-motion'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AiFillCamera, AiOutlineArrowLeft, AiOutlineHighlight, AiOutlineShopping, AiFillLeftCircle, AiFillRightCircle  } from 'react-icons/ai'
import { useSnapshot } from 'valtio'
import { state } from './store'
import { isBrowser, isMobile } from 'react-device-detect';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

state.photos = {};
fetch('https://photos.wat-mirror.com/api.php?/content', {
  headers: new Headers({
	"X-Koken-Token": "c5c82410199a62f2927c11c50c52dace",
})})
 .then((response) => response.json())
 .then((data) => {
	 data.content.forEach((photo) => {
		 var temp_tags = [];
		photo.tags.forEach((tag) => {
			temp_tags.push(tag.title)
		})
		 var temp_srcset = [];
		Object.keys(photo.presets).forEach((preset) => {
			temp_srcset.push({src: photo.presets[preset].url, width: photo.presets[preset].width, height: photo.presets[preset].height})
		})
		temp_srcset.push({src: photo.original.url, width: photo.original.width, height: photo.original.height})
		if(temp_tags.length > 0 ) state.photos[photo.id] = {
			"title": photo.title,
			"tags": temp_tags,
			"thumb": photo.presets.medium.url,
			"srcSet": temp_srcset,
			"src": photo.original.url,
			"url": photo.original.url,
		}
	 });
	console.log(state.photos);
 })
 .catch((err) => {
	console.log(err.message);
 });
export function Overlay() {
  const snap = useSnapshot(state)
  const transition = { type: 'spring', duration: 0.8 }
  const config = {
    initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
    animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
    exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
  }
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
      <motion.header initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} transition={transition}>
      </motion.header>
      <AnimatePresence>
        {snap.intro ? (
          <motion.section key="main" {...config}>
            <div className="section--container">
              <motion.div
                key="title"
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ type: 'spring', damping: 5, stiffness: 40, restDelta: 0.001, duration: 0.3 }}>
                <h1>MAPS</h1>
              </motion.div>
              <div className="support--content">
                <motion.div
                  key="p"
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: 'spring',
                    damping: 7,
                    stiffness: 30,
                    restDelta: 0.001,
                    duration: 0.6,
                    delay: 0.2,
                    delayChildren: 0.2
                  }}>
                  <p>
				  One-of-a-kind etched mirror maps of Cambodian cities offering a high-definition and contemporary perspective on Cambodian geography
                  </p>
                  <button style={{ background: snap.color }} onClick={() => (state.intro = false)}>
                    CUSTOMIZE IT <AiOutlineHighlight size="1.3em" />
                  </button>
                </motion.div>
              </div>
            </div>
          </motion.section>
        ) : (
          <motion.section key="custom" {...config}>
            <CustomizerHeader />
			<CustomizerSide />
			<Customizer />
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}

function updateStyle(style) {
	if(style == 'custom') {
		// state.custom = true;
	} else {
		// state.custom = false;
		updateFrameColor(state.styles[style].color);
		updateBackground(state.styles[style].background_color);
		updateRoads(state.styles[style].road_color);
		updateWater(state.styles[style].water_color);
		if(state.styles[style].environment) {
			console.log(state.styles[style].environment);
			state.environment = state.styles[style].environment;
		}
	}
	if(state.road_styles[state.styles[style].road_color].night || state.water_styles[state.styles[style].water_color].night) {
		state.night = true;
	} else {
		state.night = false;
	}
	state.styles.custom.stylefile = state.styles[style].stylefile;
	state.style = style;
}
function updatePreset(preset) {
	updateFrameColor(state.presets[preset].color);
	updateBackground(state.presets[preset].background_color);
	updateRoads(state.presets[preset].road_color);
	updateWater(state.presets[preset].water_color);
	state.preset = preset;
	if(state.road_styles[state.presets[preset].road_color].night || state.water_styles[state.presets[preset].water_color].night) {
		state.night = true;
		console.log("Night Mode");
	} else {
		state.night = false;
		console.log("Day Mode", state.road_styles[state.presets[preset].road_color], state.water_styles[state.presets[preset].water_color]);
	}
}
function updateFrameColor(color) {
	// console.log(color);
	state.color = color;
}
function updateBackground(color) {
	// console.log(color);
	state.background_color = color;
}
function updateRoads(color) {
	// console.log(color);
	state.road_color = color;
}
function updateWater(color) {
	// console.log(color);
	state.water_color = color;
}

function updateCity(city) {
	console.log("Update size to "+city);
	if(state.mirrors[city]) {
		if(state.mirrors[city].sizes[state.orientation]) {
			if(state.mirrors[city].sizes[state.orientation]) {
				state.city = city;
				updateOrientation(state.orientation)
			}
		} else {
			const orientation = Object.keys(state.mirrors[city].sizes)[0];
			state.city = city;
			updateOrientation(orientation)
		}
	} else {
		console.log('City does not exist');
	}
	
	// if(state.mirrors[state.city].sizes[state.orientation][size]) {
		// if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom]) {
			// console.log("Loading zoom "+state.zoom);
			// state.size = size;
		// } else {
			// console.log(state.mirrors[state.city].sizes[state.orientation][size]['default']);
			// console.log("Loading first zoom available "+state.mirrors[state.city].sizes[state.orientation][size]['default']);
			// state.zoom = state.mirrors[state.city].sizes[state.orientation][size]['default'];
			// state.size = size;
		// }
	// }
}
function updateOrientation(orientation) {
	console.log("Update orientation to "+orientation);
	const size = Object.keys(state.mirrors[state.city].sizes[orientation])[Object.keys(state.mirrors[state.city].sizes[orientation]).length - 1];
	console.log(Object.keys(state.mirrors[state.city].sizes[orientation]).length - 1);
	state.orientation = orientation;
	updateSize(size);
}

function updateOrientationAndSize(orientation, size) {
	state.orientation = orientation;
	updateSize(size);
}

function updateSize(size) {
	console.log("Update size to "+size);
	if(state.mirrors[state.city].sizes[state.orientation][size]) {
		if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom]) {
			console.log("Loading zoom "+state.zoom);
			console.log(state.mirrors[state.city].sizes[state.orientation][size][state.zoom]);
			if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom].indexOf(state.style) == -1) {
				console.log("Updating style to pro");
				state.style = "pro";
				updateStyle("pro");
			}
			state.size = size;
		} else {
			console.log("Loading first zoom available "+Object.keys(state.mirrors[state.city].sizes[state.orientation][size])[0]);
			state.zoom = Object.keys(state.mirrors[state.city].sizes[state.orientation][size])[0];
			console.log(state.style, state.mirrors[state.city].sizes[state.orientation][size][state.zoom].indexOf(state.style));
			if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom].indexOf(state.style) == -1) {
				console.log("Updating style to pro");
				state.style = "pro";
				updateStyle("pro");
			}
			state.size = size;
		}
	}
}
function updateZoom(zoom) {
	// console.log(color);
	if(state.mirrors[state.city].sizes[state.orientation][state.size][zoom][state.style]) {
		state.zoom = zoom;
	} else {
		updateStyle("pro");
		state.zoom = zoom;
	}
}

function CustomizerHeader() {
  const snap = useSnapshot(state);
  return (
    <div className="customizer-header">
    </div>
  )
}
function goToCustomize() {
	console.log("goToCustomize");
	state.custom = true;
	state.order = false;
	setTimeout(() => { 
		document.getElementById('sidebar_frame').scroll({top: 0, behavior: 'smooth'})
	}, 500)
  
}
function goToOrder() {
	console.log("goToOrder");
	state.order = true;
	setTimeout(() => { 
		document.getElementById('sidebar_frame').scroll({top: 0, behavior: 'smooth'})
	}, 500)
  
}

function CustomizerSide() {
  const snap = useSnapshot(state);
  const [open, setOpen] = React.useState(false);
  const sidebar_frame = useRef()
  const slider = useRef()
  const slider_sizes = useRef()
  const slider_style = useRef()
  const [val, setVal] = useState(false); //initializes checkbox to false 
  const transition = { type: 'spring', duration: 0.8 } 
  const updateLogo = (event) => {
    state.logo = event.target.checked;
  };
  const scrollToSizes = () => {
  const {current} = slider_sizes
	   if (current !== null){
		 current.scrollIntoView({behavior: "smooth"})
	   }
	   window.scrollTo({ top: 1000, behavior: "smooth" })
	}
  if(!state.total) state.total = {}
	if(!state.total[snap.city]) {
		{Object.keys(state.mirrors).map((city) => (
			state.total[city] = {
				sizes: 0,
				zoom: 0,
				styles: 0,
				total : 0
			},
			Object.keys(state.mirrors[city].sizes).map((orientation) => (
				Object.keys(state.mirrors[city].sizes[orientation]).map((size) => (
					state.total[city].sizes++
				))
			))
		))}
	}
	const filtered_photos = Object.keys(state.photos).filter(function(item)
	{
		return (state.photos[item].tags.indexOf(state.frame_sizes[snap.orientation][snap.size].tag) > -1 && state.photos[item].tags.indexOf(state.mirrors[snap.city].tag) > -1 && state.photos[item].tags.indexOf("maps") > -1)
	})
    for (var i = filtered_photos.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = filtered_photos[i];
        filtered_photos[i] = filtered_photos[j];
        filtered_photos[j] = temp;
    }
	console.log("Photos:", filtered_photos);

  // console.log(.indexOf(style));
  // <img style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/10+"cm", width: state.frame_sizes[orientation][size].width/10+"cm"}} className="frame_border" src={"/"+orientation+"_empty.svg"} />

  useEffect( () => {
	  
	if(snap.water_color == 'light' || snap.road_color == 'light') {
		state.night = true;
	} else {
		state.night = false;
	}
	if(snap.water_color == 'mirror' && snap.road_color == 'mirror' && snap.background_color == 'mirror') {
		state.onlymirror = true;
	} else {
		state.onlymirror = false;
	}
	if(snap.water_color !== 'mirror' && snap.road_color !== 'mirror' && snap.background_color !== 'mirror') {
		state.nomirror = true;
	} else {
		state.nomirror = false;
	}
  if ( slider.current ) {
	slider.current.go(Object.keys(snap.mirrors).indexOf(snap.city));
  }
  if ( slider.current ) {
	slider.current.go(Object.keys(snap.mirrors).indexOf(snap.city));
  }
  if ( slider_sizes.current ) {
	slider_sizes.current.go(Object.keys(snap.mirrors[snap.city].sizes[snap.orientation]).indexOf(snap.size) + 1);
  }
} );
  return (
    <div className="customizer-side" >
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        plugins={[Fullscreen, Thumbnails, Zoom]}
        slides={filtered_photos.map((photo) => (state.photos[photo]))}
      />
	  {filtered_photos.length > 0 && !snap.custom ? (
		<div class="photo-container">
			{filtered_photos.slice(0, 3).map((photo, index) => (
			  <div  onClick={() => setOpen(true)} style={{"--r": -8 + (10 * index), "background-image": "url("+state.photos[photo].thumb+")"}} className="glass">
				
			  </div>
			))}
		</div>
	  ) : null}
		<div className="frame" id="sidebar_frame" ref={sidebar_frame}>
			{snap.custom ? (
				snap.order ? (
					<motion.div className="order_container" key="step3" initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} transition={transition}>
						<p>Contact us and send a screenshot</p>
						<h3>{snap.mirrors[snap.city].name}</h3>
						<span>{snap.size} ({snap.orientation}) 1:{snap.zoom}</span><br/>
						<span>Theme: {snap.style} {snap.preset}</span><br/>
						<span>Background: {snap.background_color} / Roads: {snap.road_color} / Water: {snap.water_color}</span><br/>
							{snap.logo ? (
							<span>With custom logo or text</span>
							) : null}
					</motion.div>
				) : (
				<motion.div key="step2" initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={transition}>
					{Object.keys(snap.mirrors[snap.city].sizes[snap.orientation][snap.size]).length > 1 ? (
						<div className="customize_option">
							<img className="typo" src={"/scale.svg"} />
							<div className="radio-inputs">
								{Object.keys(snap.mirrors[snap.city].sizes[snap.orientation][snap.size]).map((zoom) => (
									<div className={snap.zoom == zoom ? "radio-tile zoom-tile active" : "radio-tile zoom-tile"}  onClick={() => (updateZoom(zoom))}>
										<span className="radio-label">
											<span className="zoom_numeric">1:{zoom}</span><br />
											<span className="zoom_cm">1CM = {zoom / 100}M</span>
										</span>
									</div>
								))}
							</div> 
						</div> 
					) : null}
					{state.mirrors[snap.city].sizes[snap.orientation][snap.size][snap.zoom].length > 1 ? (
						<div className="customize_option">
							<img className="typo" src={"/theme.svg"} />
							<div className="radio-inputs">
								{state.mirrors[snap.city].sizes[snap.orientation][snap.size][snap.zoom].map((style) => (
									<div title={style} className={snap.style == style ? `radio-tile city-tile active` : `radio-tile city-tile`} onClick={() => (updateStyle(style))}>
										<span className="radio-label">
											{state.styles[style].name}
										</span>
									</div> 
								))}
							</div> 
						</div> 
					) : null}
						<div className="customize_option">
							<img className="typo" src={"/style.svg"} />
							<div className="radio-inputs styles" style={{width: "calc(100% - 40px)"}} >
							
								<Splide tag="div" onReady={( splide ) => { setTimeout(() => { splide.emit('resized') }, 500) }} aria-label="Cities" ref={slider_style}  options={ {width : "100%", height : '64px', padding: '4px', gap : '4px', 'autoWidth': true, focus: 'center',drag   : 'free', arrows:false} }>
									{Object.keys(snap.presets).map((style, key) => (
										  <SplideSlide key={key} className={snap.preset == style ? "radio-tile active "+style : "radio-tile "+style}  onClick={() => (updatePreset(style))}>
											<span className="radio-label">
												{state.presets[style].name}
											</span>
										  </SplideSlide>
									))}
								</Splide>
							</div>
						</div>
						
						<div className="customize_option">
							<img className="typo" src={"/frame_color.svg"} />
							<div className="radio-inputs colors">
								{snap.colors.map((color) => (
									<div className={snap.color == color ? "radio-tile active" : "radio-tile"} style={{ background: color }} onClick={() => (updateFrameColor(color))}>
										<span className="radio-label">
										</span>
									</div>
								))}
							</div>
						</div>
						<div className="customize_option">
							<img className="typo" src={"/background_color.svg"} />
							<div className="radio-inputs colors">
								{Object.keys(snap.background_styles).map((color) => (
									<div className={snap.background_color == color ? "radio-tile active "+color : "radio-tile "+color} style={{ background: snap.background_styles[color].color }} onClick={() => (updateBackground(color))}>
										<span className="radio-label">
											{state.background_styles[color].name}
										</span>
									</div>
								))}
							</div>
						</div>
						<div className="customize_option">
							<img className="typo" src={"/roads_color.svg"} />
							<div className="radio-inputs colors">
								{Object.keys(snap.road_styles).map((color) => (
									<div className={snap.road_color == color ? "radio-tile active "+color : "radio-tile "+color} style={{ background: snap.road_styles[color].color }} onClick={() => (updateRoads(color))}>
										<span className="radio-label">
											{snap.road_styles[color].name}
										</span>
									</div>
								))}
							</div>
						</div>
						<div className="customize_option">
							<img className="typo" src={"/water_color.svg"} />
							<div className="radio-inputs colors">
								{Object.keys(snap.water_styles).map((color) => (
									<div className={snap.water_color == color ? "radio-tile active "+color : "radio-tile "+color} style={{ background: snap.water_styles[color].color }} onClick={() => (updateWater(color))}>
										<span className="radio-label">
											{snap.water_styles[color].name}
										</span>
									</div>
								))}
							</div>
						</div>
						<div className="customize_option">
							<div>
								<span>Add Logo/Text</span><br />
								<span>Customize it with a logo or a text</span>
							</div>
							<div>
								<input  type="checkbox"  checked={snap.logo}  onChange={updateLogo} />
								<span>+5$</span>
							</div>
						</div>
						
				</motion.div>
				)
			
			) : (
			<motion.div key="step1" initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} transition={transition}>
				<Splide tag="div" onReady={( splide ) => { setTimeout(() => { splide.emit('resized') }, 500) }} aria-label="Cities" ref={slider}  options={ {width : "100%", height : '64px', padding: '4px', gap : '4px', 'autoWidth': true, focus: 'center',drag   : 'free',} }>
					{Object.keys(snap.mirrors).map((city, key) => (
						  <SplideSlide key={key} className={snap.city == city ? "city_selector_item active" : "city_selector_item"} onClick={() => (scrollToSizes, updateCity(city))}>
							<img alt={snap.mirrors[city].name} src={'/maps/'+city+'/font.svg'} />
								{state.total[city].sizes > 1 ? <span className="badge">{state.total[city].sizes}</span> : null}
						  </SplideSlide>
					))}
				</Splide>
				<Splide tag="div"  className="size_selector" aria-label="Cities" ref={slider_sizes}  options={ { width : "100%", padding: '4px', gap : '4px', 'autoWidth': true, focus: 'center',drag   : 'free', arrows:false} }>
					{Object.keys(snap.mirrors[snap.city].sizes).map((orientation) => (
						Object.keys(snap.mirrors[snap.city].sizes[orientation]).map((size) => (
						  <SplideSlide key={size} className="size_item_container" onClick={() => (updateOrientationAndSize(orientation, size))}>
							<div style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/15+"cm", width: state.frame_sizes[orientation][size].width/15+"cm"}} className={snap.size == size ? "frame_template size_item active" : "frame_template size_item"} >
							
								{ isBrowser ? <img style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/15+"cm", width: state.frame_sizes[orientation][size].width/15+"cm"}} className="water" src={"/maps/"+snap.city+"/"+orientation+"/"+size+"/"+Object.keys(snap.mirrors[snap.city].sizes[orientation][size])[0]+"/pro/water.png"} /> : null}
								{ isBrowser ? <img style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/15+"cm", width: state.frame_sizes[orientation][size].width/15+"cm"}} className="roads" src={"/maps/"+snap.city+"/"+orientation+"/"+size+"/"+Object.keys(snap.mirrors[snap.city].sizes[orientation][size])[0]+"/pro/roads.png"} /> : null}
								<span className="frame_size">{state.frame_sizes[orientation][size].width}x{state.frame_sizes[orientation][size].height}</span>
								{Object.keys(snap.mirrors[snap.city].sizes[orientation][size]).length > 1 ? <span className="badge static">{Object.keys(snap.mirrors[snap.city].sizes[orientation][size]).length}</span> : null}
								
							</div>
						  </SplideSlide>
						))
					))}
				</Splide>
			</motion.div>
			)}
			<div className="sidebar_bottom">
			  <button className="back_btn"  style={{ background: snap.color }} onClick={() => (state.custom ? state.order ? goToCustomize() : state.custom = false : state.intro = true)}>
				<AiFillLeftCircle size="1.3em" /> BACK
				{state.custom && !state.order ? <span className="legend">Select city and size</span> : null}
				{state.order ? <span className="legend">Select frame and colors</span> : null}
			  </button>
				<div className="fbutton_container price">
					<span>{snap.custom ? null : 'From '} {snap.night ? snap.custom ? state.frame_sizes[snap.orientation][snap.size].price_led : state.frame_sizes[snap.orientation][snap.size].price : state.frame_sizes[snap.orientation][snap.size].price}$</span>
					<span><small>{state.frame_sizes[snap.orientation][snap.size].price * 4000} KHR</small></span>
				</div>
				{snap.onlymirror || snap.nomirror ? (
					<div className="error">
					{snap.onlymirror ? "Only mirror!" : "No mirror!"}
					</div>
				) : (
					snap.order ? (
						<div className="links_btn">
						  <button
							style={{ background: snap.color }}
							onClick={() => {
								
								state.showCanvaText = true
							  const link = document.createElement('a')
							  link.setAttribute('download', 'canvas.png')
							  link.setAttribute('href', document.querySelector('canvas').toDataURL('image/png').replace('image/png', 'image/octet-stream'))
							  link.click()
							}}>
							<AiFillCamera size="1.3em" />
						  </button>
						  <button className="next_btn" style={{ background: snap.color }}>
								<span>FB</span>
						  </button>
						  <button className="next_btn" style={{ background: snap.color }}>
								<span>Telegram</span>
						  </button>
					  </div>
					) : (
					  <button className="next_btn" style={{ background: snap.color }} onClick={() => (snap.custom ? goToOrder() : goToCustomize())}>
							<AiFillRightCircle size="1.3em"  /> 
							<span>{snap.custom ? "Order" : "Continue"}</span>
							{!state.custom && !state.order ? <span className="legend">Select frame and colors</span> : null}
					  </button>
					)
				)}
			</div>
		</div>
    </div>
  )
}
function Customizer() {
  const snap = useSnapshot(state);
  return (
    <div className="customizer">
      
      <button
        className="share"
        style={{ background: snap.color, display: 'none' }}
        onClick={() => {
			
			state.showCanvaText = true
          const link = document.createElement('a')
          link.setAttribute('download', 'canvas.png')
          link.setAttribute('href', document.querySelector('canvas').toDataURL('image/png').replace('image/png', 'image/octet-stream'))
          link.click()
        }}>
        DOWNLOAD
        <AiFillCamera size="1.3em" />
      </button>
    </div>
  )
}
