import { proxy } from 'valtio'

const state = proxy({
  intro: true,
  colors: ['#cccccc', '#d47f32', '#000000'],
  environments: ['sunset', 'night', 'park'],
  environment: "sunset",
  road_styles: {
	"white": {
		name:"White",
		color:"#FFFFFF",
		metalness: 0.1,
		roughness: 0,
		emissiveIntensity: 0,
		emissive: "#ffffff",
		night: false,
	},
	"black": {
		name:"Black",
		color:"#000000",
		metalness: 0.1,
		roughness: 1,
		emissiveIntensity: 0,
		emissive: "#000000",
		night: false,
	},
	"gold": {
		name:"Gold",
		color:"#c4a854",
		metalness: 0.1,
		roughness: 1,
		emissiveIntensity: 0,
		emissive: "#c4a854",
		night: false,
	},
	"light": {
		name:"LED",
		color:"#FFFFFF",
		metalness: 0,
		emissiveIntensity: 1,
		roughness: 1,
		emissive: "#FFFFFF",
		night: true,
	},
	"mirror": {
		name:"/",
		color:"#FFFFFF",
		metalness: 1,
		roughness: 0,
		emissiveIntensity: 0,
		emissive: "#000000",
		night: false,
	},
  },
  water_styles: {
	"tosca_green": {
		name:"Tosca",
		color:"#00b0a3",
		metalness: 0.1,
		roughness: 1,
		emissiveIntensity: 0,
		emissive: "#00b0a3",
		night: false,
	},
	"blue": {
		name:"Blue",
		color:"#25a9df",
		metalness: 0.1,
		roughness: 1,
		emissiveIntensity: 0,
		emissive: "#25a9df",
		night: false,
	},
	"tivoli_blue": {
		name:"Tivoli",
		color:"#31009d",
		metalness: 0.1,
		roughness: 1,
		emissiveIntensity: 0,
		emissive: "#31009d",
		night: false,
	},
	"black": {
		name:"Black",
		color:"#000000",
		metalness: 0.1,
		roughness: 1,
		emissiveIntensity: 0,
		emissive: "#000000",
		night: false,
	},
	"white": {
		name:"White",
		color:"#ffffff",
		metalness: 0.1,
		roughness: 0,
		emissiveIntensity: 0,
		emissive: "#ffffff",
		night: false,
	},
	"light": {
		name:"LED",
		color:"#FFFFFF",
		metalness: 0,
		emissiveIntensity: 1,
		emissive: "#FFFFFF",
		roughness: 1,
		show:false,
		night: true,
	},
	"mirror": {
		name:"Mirror",
		color:"#ffffff",
		metalness: 1,
		roughness: 0,
		emissiveIntensity: 0,
		emissive: "#000000",
		night: false,
	}
  },
  background_styles: {
	"black": {
		name:"Black",
		color:"#000000",
		metalness: 0.1,
		roughness: 1,
		emissiveIntensity: 0,
	},
	"mirror": {
		name:"/",
		color:"#ffffff",
		metalness: 1,
		roughness: 0,
		emissiveIntensity: 0,
	},
	// "light": {
		// color:"#FFFFFF",
		// metalness: 0,
		// roughness: 1,
		// emissiveIntensity: 1,
		// show:false
	// }
  },
  sizes: ['12x18', '15x20', '20x25', '24x30', '30x40', '40x60'],
  frame_sizes: {
	  landscape: {
		'12x18': {
			width: 18,
			height: 12,
			zoom: 2,
			price: 15,
			price_led: 30,
		},
		'15x20': {
			width: 20,
			height: 15,
			zoom: 1.2,
			price: 18,
			price_led: 36,
			tag: "15.5x20cm",
		},
		'20x25': {
			width: 25,
			height: 20,
			zoom: 2,
			price: 20,
			tag: "20x25cm",
		},
		'24x30': {
			width: 30,
			height: 24,
			zoom: 2,
			price: 36,
			tag: "24x30cm",
		},
		'30x40': {
			width: 40,
			height: 30,
			zoom: 2,
			price: 54,
			tag: "30x40cm",
		},
		'40x60': {
			width: 60,
			height: 40,
			zoom: 2.2,
			price: 96,
			tag: "40x60cm",
		},
	  },
	  portrait: {
		'12x18': {
			width: 12,
			height: 18,
			zoom: 2,
			price: 10,
			price_led: 30,
		},
		'15x20': {
			width: 15,
			height: 20,
			zoom: 2,
			price: 18,
			price_led: 36,
			tag: "15.5x20cm",
		},
		'20x25': {
			width: 20,
			height: 25,
			zoom: 1.2,
			price: 20,
			price_led: 50,
			tag: "20x25cm",
		},
		'24x30': {
			width: 24,
			height: 30,
			zoom: 1.5,
			price: 36,
			price_led: 60,
			tag: "24x30cm",
		},
		'30x40': {
			width: 30,
			height: 40,
			zoom: 1.8,
			price: 54,
			price_led: 96,
			tag: "30x40cm",
		},
		'30x60': {
			width: 30,
			height: 60,
			zoom: 2.3,
			price: 78,
			price_led: 144,
			tag: "30x60cm",
		},
		'40x60': {
			width: 40,
			height: 60,
			zoom: 2.3,
			price: 96,
			price_led: 168,
			tag: "40x60cm",
		},
	  },
  },
  orientation: ['landscape', 'portrait'],
  cities: ['kampot', 'phnompenh', 'sihanoukville', 'siemreap'],
  styles: {
	"classic": {
		name: "Classic",
		environment: "sunset",
		stylefile: "classic",
		color: "#d47f32",
		road_color: 'black',
		water_color: 'blue',
		background_color: 'mirror',
	},
	"pro": {
		name: "Original",
		environment: "sunset",
		stylefile: "pro",
		color: "#d47f32",
		road_color: 'black',
		water_color: 'blue',
		background_color: 'mirror',
	},
	"vintage": {
		name: "Vintage",
		environment: "sunset",
		stylefile: "old",
		color: "#d47f32",
		road_color: 'black',
		water_color: 'blue',
		background_color: 'mirror',
	},
	"night": {
		name: "Invert",
		environment: "sunset",
		stylefile: "pro",
		color: "#000000",
		road_color: 'mirror',
		water_color: 'blue',
		background_color: 'black',
	},
	// "light": {
		// environment: "night",
		// stylefile: "pro",
		// color: "#d47f32",
		// road_color: 'light',
		// water_color: 'blue',
		// background_color: 'mirror',
		// show:false
	// },
	"custom": {
		stylefile: "pro",
		color: "#d47f32",
		road_color: 'black',
		water_color: 'blue',
		background_color: 'mirror',
	}
  },
  presets: {
	"original": {
		name: "Original",
		color: "#d47f32",
		road_color: 'black',
		water_color: 'blue',
		background_color: 'mirror',
		night: false,
	},
	// "light": {
		// name: "Light",
		// color: "#cccccc",
		// road_color: 'black',
		// water_color: 'white',
		// background_color: 'mirror',
		// night: false,
	// },
	"dark": {
		name: "Dark",
		color: "#000000",
		road_color: 'black',
		water_color: 'black',
		background_color: 'mirror',
		night: false,
	},
	"night": {
		name: "Night",
		color: "#000000",
		road_color: 'mirror',
		water_color: 'blue',
		background_color: 'black',
		night: true,
	},
	"LED": {
		name: "LED",
		color: "#d47f32",
		road_color: 'light',
		water_color: 'blue',
		background_color: 'mirror',
		light: true,
		night: true,
	},
  },
	mirrors: {
		"bangkok": {
			name: "Bangkok",
			tag: "bangkok",
			country: "th",
			default: {
				orientation: "portrait",
				size: "30x40",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				landscape: {
					'15x20': {
						'37500': ['pro'],
					},
				},
				portrait: {
					'30x40': {
						'25000': ['pro'],
					},
					'30x60': {
						'25000': ['pro'],
					},
				},
			},
		},
		"battambang": {
			name: "Battambang",
			tag: "battambang",
			country: "kh",
			default: {
				orientation: "portrait",
				size: "30x40",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				portrait: {
					'30x40': {
						'25000': ['pro'],
					},
				},
			},
		},
		"kampongcham": {
			name: "Kampong Cham",
			country: "kh",
			tag: "kampong-cham",
			default: {
				orientation: "portrait",
				size: "24x30",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				portrait: {
					'24x30': {
						'25000': ['pro'],
					},
				},
			},
		},
		"kampongchhnang": {
			name: "Kampong Chhnang",
			tag: "kampong-chhnang",
			country: "kh",
			default: {
				orientation: "portrait",
				size: "30x40",
				zoom: "75000",
				style: "pro"
			},
			sizes: {
				portrait: {
					'30x40': {
						'75000': ['pro'],
					},
				},
			},
		},
		"kampot": {
			name: "Kampot",
			country: "kh",
			tag: "kampot",
			default: {
				orientation: "portrait",
				size: "40x60",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				landscape: {
					'15x20': {
						'17500': ['pro'],
					},
				},
				portrait: {
					'24x30': {
						'60000': ['classic', 'pro', 'vintage'],
						'25000': ['pro'],
					},
					'30x40': {
						'10000': ['pro'],
						'25000': ['pro'],
						'100000': ['pro'],
					},
					'40x60': {
						'25000': ['pro'],
					},
				},
			},
		},
		"kohkong": {
			name: "Koh Kong",
			country: "kh",
			tag: "koh-kong",
			default: {
				orientation: "portrait",
				size: "30x40",
				zoom: "50000",
				style: "pro"
			},
			sizes: {
				portrait: {
					'30x40': {
						'50000': ['pro'],
					},
				},
			},
		},
		"hochiminh": {
			name: "Ho Chi Minh",
			country: "vn",
			tag: "ho-chi-minh",
			default: {
				orientation: "portrait",
				size: "30x60",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				landscape: {
					'15x20': {
						'37500': ['pro'],
					},
				},
				portrait: {
					'30x60': {
						'25000': ['pro'],
					},
				},
			},
		},
		"phnompenh": {
			name: "Phnom Penh",
			country: "kh",
			tag: "phnom-penh",
			default: {
				orientation: "portrait",
				size: "30x40",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				landscape: {
					'15x20': {
						'50000': ['pro'],
					},
				},
				portrait: {
					'24x30': {
						'25000': ['pro'],
					},
					'30x40': {
						'25000': ['pro']
					},
					'30x60': {
						'25000': ['pro']
					},
					'40x60': {
						'25000': ['pro']
					},
				},
			},
		},
		"siemreap": {
			name: "Siem Reap",
			country: "kh",
			tag: "siem-reap",
			default: {
				orientation: "portrait",
				size: "40x60",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				landscape: {
					'15x20': {
						'17500': ['pro'],
					},
				},
				portrait: {
					'30x40': {
						'25000': ['pro'],
					},
					'30x60': {
						'25000': ['pro'],
					},
					'40x60': {
						'25000': ['pro'],
					},
				},
			},
		},
		"sihanoukville": {
			name: "Sihanoukville",
			country: "kh",
			tag: "sihanoukville",
			default: {
				orientation: "portrait",
				size: "40x60",
				zoom: "25000",
				style: "pro"
			},
			sizes: {
				portrait: {
					'30x40': {
						'75000': ['pro'],
						'25000': ['pro'],
					},
					'40x60': {
						'25000': ['pro'],
					},
				},
			},
		},
	},
  frame_colors: ['#ccc', '#EFBD4E', '#80C670', '#726DE8', '#EF674E', '#353934'],
  decals: ['day', 'night'],
  city: 'phnompenh',
  size: '40x60',
  orientation: 'portrait',
  zoom: '25000',
  style: 'pro',
  color: '#d47f32',
  road_color: 'black',
  water_color: 'blue',
  background_color: 'mirror',
  add_logo: false,
  decal: 'day',
  preset: 'original',
  showCanvaText: false,
  custom: false,
  onSidebar: false,
  night: false,
  onlymirror: false,
  nomirror: false,
  order: false,
  logo: false,
})

export { state }
